import airpodRight from '../../images/airpod-right.png';
import airpodLeft from '../../images/airpod-left.png';
import rainbow from '../../images/rainbow.png';

const BackgroundAssets = () => {
  return (
    <>
      <img className="airpod-right" src={airpodLeft} alt="Airpod Right" />
      <img className="airpod-left" src={airpodRight} alt="Airpod Left" />
      <img className="rainbow" src={rainbow} alt="Rainbow" />
    </>
  );
};

export default BackgroundAssets;
