import axios from 'axios';

export const getSurveyData = async (surveyName) => {
  const response = await axios.get(`/api/survey/${surveyName}`);
  return response.data;
};

export const getSurveyQuestions = async (id) => {
  const response = await axios.get(`/api/survey/questions?survey=${id}`);
  return response.data;
};

export const saveIntroduction = async (body) => {
  const response = await axios.post('/api/survey/introduction', body);
  return response;
};

export const checkQuizAnswers = async (body) => {
  const response = await axios.post('/api/survey/check-out', body);
  return response.data;
};

export const saveFeedback = async (body) => {
  const response = await axios.post('/api/survey/feedback', body);
  return response;
};

export const submitSurvey = async (surveyData) => {
  const response = await axios.get(
    `https://www.tp88trk.com/?nid=725&transaction_id=${surveyData.transactionId}&verification_token=013Fjq4bwUF04RRNtHNImhGRNvkXl8`
  );
  return response;
};

export const saveSocialMediaClick= async (body) => {
  const response = await axios.post('/api/survey/click-btn', body);
  return response;
};

export const parseRss = async (rssLink) => {
  const response = await axios.get(`/api/rss?rss=${rssLink}&all=1&withFeedHeader=1`);
  return response.data;
}
