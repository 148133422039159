import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Survey from '../../components/Survey/Survey';
import { getSurveyData, getSurveyQuestions, parseRss } from '../../api/survey';
import { useSurvey } from '../../context/SurveyProvider';
import { updateSurveyAnswers } from '../../utils/utils';

const SurveyPage = () => {
  const { surveyName, stepName, aff_click_id } = useParams();
  const { updateSurveyData, surveyData } = useSurvey();
  const navigate = useNavigate();

  useEffect(() => {
    if (!stepName) {
      navigate(`/survey/${surveyName}/introduction`, { replace: true });
    }

    getSurveyData(surveyName).then((resp) => {
      const { _id } = resp;

      updateSurveyData({
        surveyId: _id,
        ...resp,
      });
    });
  }, [surveyName]);

  useEffect(() => {
    if (surveyData.rssLink) {
      parseRss(surveyData.rssLink).then((resp) => {
        const episode = resp.items.find((ep) => ep.title === surveyData.episode).itunes;

        updateSurveyData({ podcastImage: resp.image.url, episodeImage: episode.image });
      });
    }
  }, [surveyData.rssLink]);

  useEffect(() => {
    if (surveyData.surveyId) {
      getSurveyQuestions(surveyData.surveyId).then((resp) => {
        updateSurveyData({ questions: resp });
      });
    }
  }, [surveyData.surveyId]);

  useEffect(() => {
    if (aff_click_id && surveyData) {
      updateSurveyData({ transactionId: aff_click_id });
    }
  }, [aff_click_id]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('sub2')) {
      const sub2 = urlParams.get('sub2');
      updateSurveyAnswers('introduction', { sub2 });
    }
  }, []);

  useEffect(() => {
    if (window.EF) {
        window.EF.click({
        offer_id: window.EF.urlParameter('oid'),
        affiliate_id: window.EF.urlParameter('affid'),
        sub1: window.EF.urlParameter('sub1'),
        sub2: window.EF.urlParameter('sub2'),
        sub3: window.EF.urlParameter('sub3'),
        sub4: window.EF.urlParameter('sub4'),
        sub5: window.EF.urlParameter('sub5'),
        uid: window.EF.urlParameter('uid'),
        source_id: window.EF.urlParameter('source_id'),
        transaction_id: window.EF.urlParameter('aff_click_id'),
      });
    }
  }, [surveyName]);

  return <Survey currentStep={stepName || 'introduction'} />;
};

export default SurveyPage;
