import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyProvider';
import BackgroundAssets from '../BackgroundAssets/BackgroundAssets';
import { getSurveyAnswers, updateSurveyAnswers } from '../../utils/utils';
import { saveFeedback, submitSurvey } from '../../api/survey';
import {FeedbackInput} from './inputs'

const Feedback = () => {
  const { surveyName } = useParams();
  const navigate = useNavigate();
  const { surveyData } = useSurvey();

  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [feedbackAnswers, setFeedbackAnswers] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (surveyData.questions) {
      const questions = surveyData.questions.filter((q) => q.type === 'question');
      setFeedbackQuestions(questions);
    }
  }, [surveyData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFeedbackAnswers((prev) => ({ ...prev, [name]: value }));

    if (value.trim()) {
      clearErrors(name);
    }
  };

  useEffect(() => {
    updateSurveyAnswers('feedback', {
      ...feedbackAnswers,
    });
  }, [feedbackAnswers]);

  const clearErrors = (field) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const validateFeedback = () => {
    let validated = true;
    const newErrors = {};

    feedbackQuestions.forEach((question) => {
      if (!feedbackAnswers[question.fieldName] || feedbackAnswers[question.fieldName].trim() === '') {
        newErrors[question.fieldName] = 'Required';
        validated = false;
      }
    });

    setErrors(newErrors);

    return validated;
  };

  const handleNextStep = async (event) => {
    event.preventDefault();

    if (validateFeedback()) {
      const body = { surveyId: surveyData.surveyId, ...getSurveyAnswers() };

      try {
        await saveFeedback(body);
        await submitSurvey(surveyData);
      } catch (error) {
        console.error(error);
      }

      navigate(`/survey/${surveyName}/thank-you`);
    }
  };

  useEffect(() => {
    const surveyAnswers = getSurveyAnswers();

    if (!surveyAnswers.quiz) {
      navigate(`/survey/${surveyName}/quiz`);
      return;
    }

    const quizAnswers = surveyData.questions
      ?.filter((q) => q.type === 'test')
      .every((q) => surveyAnswers.quiz[q.fieldName]);

    if (!quizAnswers && surveyData.questions) {
      navigate(`/survey/${surveyName}/quiz`);
    }
  }, [surveyData]);

  const renderFeedbackQuestions = () => {
    return feedbackQuestions.map((question, index) => {
      return (
        <fieldset key={index}>
          <FeedbackInput type={question.inputType} errors={errors} question={question} onChange={handleChange}  />
        </fieldset>
      );
    });
  };

  return (
    <div className="step-three">
      <BackgroundAssets />
      <main>
        <h1>mowPod Survey: {surveyData.name}</h1>
        <form className="survey-form step3" id="3">
          <legend>Lastly, give us your feedback on the show!</legend>
          {renderFeedbackQuestions()}
          <div className="bottom__next-step">
            <button className="submit-btn" onClick={handleNextStep}>
              NEXT
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Feedback;
