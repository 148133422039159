export const getSurveyAnswers = () => {
  const surveyData = JSON.parse(sessionStorage.getItem('survey')) || {};
  return surveyData;
};

export const updateSurveyAnswers = (step, newAnswers) => {
  const surveyData = JSON.parse(sessionStorage.getItem('survey')) || {};

  surveyData[step] = {
    ...surveyData[step],
    ...newAnswers,
  };

  sessionStorage.setItem('survey', JSON.stringify(surveyData));
};

export const getFailedQuizAmount = (surveyId) => {
  const surveyTries = JSON.parse(localStorage.getItem('surveyFailedTries')) || [];
  const survey = surveyTries.find((s) => s.surveyId === surveyId);
  return survey ? survey.failedTries : 0;
};

export const setFailedQuizAmount = (surveyId) => {
  const surveyTries = JSON.parse(localStorage.getItem('surveyFailedTries')) || [];
  const survey = surveyTries.find((s) => s.surveyId === surveyId);

  if (survey) {
    survey.failedTries += 1;
  } else {
    surveyTries.push({ surveyId, failedTries: 1 });
  }

  localStorage.setItem('surveyFailedTries', JSON.stringify(surveyTries));
};

export const checkIntroductionFilled = (answers) => {
  if (!answers.introduction) {
    return false;
  }

  const { email, name } = answers.introduction;

  return !!email && !!name;
};

export const scrollToRef = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};
