import { useEffect, useRef } from 'react';

const TermsOfService = ({ toggleTermsOfService }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleTermsOfService();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleTermsOfService]);

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <span className="modal-close" onClick={toggleTermsOfService}>
          &times;
        </span>
        <div className="modal-text">
          <h1>Terms of Service</h1>
          <p>
            Welcome to MowMedia’s/MowPod’s Terms of Service! This page explains our terms of service, which
            contain important information about certain legal rights that you may or may not be aware of. When
            you take a survey on mowPod.com, you are agreeing to these terms.
          </p>
          <p>
            These Terms of Service ("Terms") cover your use of and access to the sites, surveys, tools,
            services and features (collectively, the "Services") provided by MowMedia and/or MowPod
            (“MowMedia”, “us”, “we”, and “our”) on the internet, mobile and all other applications.
          </p>
          <p>
            Please read this Agreement (as defined below) carefully! IT INCLUDES IMPORTANT INFORMATION ABOUT
            YOUR LEGAL RIGHTS, AND COVERS AREAS SUCH AS WARRANTY DISCLAIMERS, LIMITATIONS OF LIABILITY, THE
            RESOLUTION OF DISPUTES BY ARBITRATION AND A CLASS ACTION WAIVER. Please note if you are an EU
            Consumer (as defined below), some of these provisions may not apply to you and you may be entitled
            to specific rights under the mandatory laws of the country in which you reside.
          </p>
          <p>
            By using or accessing the Services, you're agreeing to these Terms and our{' '}
            <a href="#privacy-policy">Privacy Policy</a>, (collectively, this “Agreement”). If you're using
            the Services for or on behalf of an organization, you're agreeing to this Agreement on behalf of
            that organization, and you represent and warrant that you can do so. References to “you”, “your”
            and similar terms are construed accordingly in this Agreement. If you don’t agree to all the terms
            in this Agreement, you may not use or access the Services at any point in time.
          </p>
          <p>
            If you are a resident of or have your principal place of business in the United States of America
            including any of its territories or possessions (the “US”), you are agreeing to this Agreement
            with us and are a “US User.” Otherwise, you are agreeing to this Agreement with us and are a
            “Non-US User.”
          </p>
          <p>
            We’ve tried to make this Agreement fair and straightforward, but feel free to contact us if you
            have any questions or suggestions.
          </p>

          <h2>1. Surveys</h2>
          <ul>
            <li>
              <strong>1.1. Participation.</strong> To participate in some of our surveys or other interactive
              initiatives, you must provide certain information that will be stored on our end as an account
              (“Account”). In exchange for participation in the Surveys you may be rewarded with cash, gift
              cards, or other game-related incentives. Different parts of the Services offered on mowPod.com
              may require different Accounts. You agree to provide us with accurate, complete and at all times
              up to date information for your Accounts. We may need to use this information to contact you.
            </li>
            <li>
              <strong>1.2. Staying Safe.</strong> Please safeguard your Accounts and make sure others don't
              have access to your Accounts or passwords and other authentication credentials, if any
              (collectively, "passwords"). We’re not liable for any acts or omissions by you or anyone else in
              connection with your Accounts.
            </li>
            <li>
              <strong>1.3. Sixteen And Older.</strong> The Services are not intended for and may not be used
              by children under the age of 16. By using the Services, you represent that you're at least 16.
              If you’re under the age of 18, depending on where you live, you may need to have your parent or
              guardian’s consent to this Agreement and they may need to enter into this Agreement on your
              behalf.
            </li>
          </ul>

          <h2>2. Third Party Services</h2>
          <p>
            Services and applications (collectively, “Third Party Services”) that may make their content,
            products or services available to you. Examples of Third Party Services include micropayment and
            reward payment processors (in connection with your participation in our Surveys or otherwise).
            These Third Party Services may have their own terms and policies, and your use of them will be
            governed by those terms and policies. Any information that a Third Party Service collects, stores
            and processes from you will be subject to such Third Party Service’s terms of service, privacy
            notice, or similar terms, and will not be subject to ours. Therefore, please evaluate and ensure
            you trust each Third Party Service prior to connecting to its services. Each Third Party Service
            is solely responsible for providing all support, maintenance and technical assistance to you with
            respect to their services. When using Third Party Services, your security is your responsibility.
            We don't control Third Party Services, and we’re not liable for Third Party Services or for any
            transaction you may enter into with them, or for what they do. We may receive a revenue share from
            Third Party Services that we recommend to you or that you otherwise engage via the Services. You
            agree that we may, at any time and in our sole discretion, and without any notice to you, suspend,
            disable access to or remove any Third Party Services. We’re not liable for any such suspension,
            disabling or removal, including without limitation for any loss of profits, revenue, data,
            goodwill or other intangible losses, or business disruption, costs or expenses you may incur or
            otherwise experience as a result (except where prohibited by applicable law). The Services may
            contain links to third party sites. When you access third party sites, you do so at your own risk.
            We don’t control and aren’t liable for those sites and what those third parties do.
          </p>

          <h2>3. Our Intellectual Property</h2>
          <ul>
            <li>
              <strong>3.1. MowMedia, LLC Owns MowMedia and MowPod.</strong> The Services are, as between you
              and us, owned by us, and are or may be protected by copyright, trade secret, trademark and other
              US and foreign laws. This Agreement doesn't grant you any right, title or interest in the
              Services, others’ User Content, our trademarks, logos or other brand features or intellectual
              property or trade secrets or others’ content in the Services. You agree not to change, modify,
              translate or otherwise create derivative works of the Services or others’ User Content.
            </li>
            <li>
              <strong>3.2. We Can Use Your Feedback For Free.</strong> We welcome your feedback, ideas or
              suggestions (collectively, “Feedback”), but you agree that we may use your Feedback without any
              restriction or obligation to you, even after this Agreement is terminated. This Section does not
              limit or affect any rights you may have under applicable data protection laws.
            </li>
          </ul>

          <h2>4. Our Rights</h2>
          <ul>
            <li>
              <strong>4.1. Important Things We Can Do.</strong> We reserve these rights, which we may exercise
              at any time and in our sole discretion, and without liability or notice to you (except where
              prohibited by applicable law): (a) we may change parts or all of the Services and their
              functionality; (b) we may suspend or discontinue parts or all of the Services; (c) we may
              terminate, suspend, restrict or disable your access to or use of parts or all of the Services;
              (d) we may terminate, suspend, restrict or disable access to your Accounts; and (e) we may
              change our eligibility criteria to use the Services (and if such eligibility criteria changes
              are prohibited by law where you reside, we may revoke your right to use the Services in that
              jurisdiction).
            </li>
            <li>
              <strong>4.2. Ownership Disputes.</strong> Sometimes ownership of an Account or site is disputed
              between parties, such as a business and its employees, or a web designer and a client. We try
              not to get involved in these disputes. However, we reserve the right, at any time and in our
              sole discretion, and without notice to you, to determine rightful Account or site ownership and
              to transfer an Account or site to such owner. Our decision in that respect is final. If we feel
              that we can’t reasonably determine the rightful owner, we reserve the right to suspend an
              Account or site until the disputing parties reach a resolution. We also may request
              documentation, such as a government-issued photo ID, credit card invoice or business license, to
              help determine the rightful owner.
            </li>
          </ul>

          <h2>5. Privacy</h2>
          <p>
            <strong>Privacy Policy.</strong> By using the Services, you confirm that you have read and
            understood our <a href="#privacy-policy">Privacy Policy</a>. However, it is not a contractual
            document and does not form part of this Agreement and we may change it from time to time.
          </p>

          <h2>6. Services Details & Product Specific Terms</h2>
          <p>
            Not all Services are available in all regions/countries. Certain Services (or features or portions
            thereof) may vary depending on your region/country.
          </p>

          <h2>7. Term And Termination</h2>
          <p>
            This Agreement will remain in effect until terminated by either you or us. You may terminate this
            Agreement at any time via emailing us at <a href="mailto:info@mowpod.com">info@mowpod.com</a>. We
            reserve the right to change, suspend or discontinue, or terminate, restrict or disable your use of
            or access to, parts or all of the Services or their functionality at any time at our sole
            discretion and without notice. For example, we may suspend or terminate your use of part or all of
            the Services if you violate these Terms. We will endeavor to provide you reasonable notice upon
            suspending or terminating part or all of the Services. All sections of this Agreement that by
            their nature should survive termination shall survive termination, including without limitation
            the following sections in these Terms and any similar sections or provisions in the rest of this
            Agreement: Your Content, Our Intellectual Property, Warranty Disclaimers, Limitation of Liability,
            Indemnification, Dispute Resolution and Additional Terms.
          </p>

          <h2>8. Warranty Disclaimers</h2>
          <ul>
            <li>
              <strong>8.1. Disclaimers.</strong> To the fullest extent permitted by applicable law, we make no
              warranties, either express or implied, about the Services. The Services are provided “as is” and
              “as available.” We also disclaim any warranties of merchantability, fitness for a particular
              purpose and non-infringement. No advice or information, whether oral or written, obtained by you
              from us, shall create any warranty. We make no warranty or representation that the Services
              will: (a) be timely, uninterrupted or error-free; (b) meet your requirements or expectations; or
              (c) be free from viruses or other harmful components.
            </li>
            <li>
              <strong>8.2. Exceptions.</strong> Under certain circumstances, some jurisdictions don't permit
              the disclaimers, so they may not apply to you. However, the disclaimers apply to the maximum
              extent permitted by applicable law. You may have other statutory rights and nothing in this
              Agreement affects your statutory rights or rights under mandatory laws. The duration of
              statutorily required warranties, if any, shall be limited to the maximum extent permitted by
              applicable law.
            </li>
          </ul>

          <h2>9. Limitation Of Liability</h2>
          <p>
            Unless you are an EU Consumer, you acknowledge and agree that to the fullest extent permitted by
            applicable law, in no event will we or our affiliates and our directors, officers, employees and
            agents be liable with respect to any claims arising out of or related to the Services or this
            Agreement for: (a) any indirect, special, incidental, exemplary, punitive or consequential
            damages; (b) any loss of profits, revenue, data, goodwill or other intangible losses; (c) any
            Losses (as defined below) related to your access to, use of or inability to access or use parts,
            some or all of your Account, or parts or all of the Services, including without limitation
            interruption of use or cessation or modification of any aspect of the Services; (d) any Losses
            related to unavailability, degradation, loss, corruption, theft, unauthorized access or,
            unauthorized alteration of, any content, information or data, including without limitation User
            Content and Your eCommerce data; (e) any User Content or other conduct or content of any user or
            third party using the Services, including without limitation defamatory, offensive or unlawful
            conduct or content; or (f) any Third Party Services or third party sites accessed via the
            Services. If you are an EU Consumer, we shall, despite any other provision in this Agreement,
            provide the Services with reasonable care but will not be liable for any losses which were not a
            reasonably foreseeable consequence of our breach of this Agreement (except in relation to death or
            personal injury resulting from our negligence or fraud). These limitations apply to any theory of
            liability, whether based on warranty, contract, tort, negligence, strict liability or any other
            legal theory, whether or not we have been informed of the possibility of such damage, and even if
            a remedy set forth in this Agreement is found to have failed its essential purpose. To the fullest
            extent permitted by applicable law (whether or not you are an EU Consumer), in no event shall the
            aggregate liability of us for all claims arising out of or related to the Services and this
            Agreement exceed one US Dollar ($1.00). If you are an EU Consumer, we are liable under statutory
            provisions for intent and gross negligence by us, our legal representatives, directors or other
            vicarious agents. An “EU Consumer” means a natural person acting for purposes outside their trade,
            business, craft or profession (as opposed to a User for business or commercial purposes)
            habitually residing in the European Economic Area or the United Kingdom.
          </p>

          <h2>10. Indemnification</h2>
          <p>
            To the fullest extent permitted by law, you agree to indemnify and hold harmless MowMedia, LLC and
            its affiliates and our and directors, officers, employees and agents from and against all damages,
            losses, liabilities, costs, claims, demands, fines, awards and expenses of any kind (including
            without limitation reasonable attorneys' fees and costs) (collectively, "Losses") arising out of
            or related to: (a) your breach of this Agreement; (b) your violation of any law or regulation or
            the rights or good name of any third party; and (c) any claims from tax authorities in any
            country. Your indemnification obligations under this Section shall not apply to the extent
            directly caused by our breach of this Agreement or, where you are an EU Consumer, to the extent
            that the consequences were not reasonably foreseeable.
          </p>

          <h2>11. Dispute Resolution</h2>
          <p>
            <strong>11.1. Applicability.</strong> Before filing a claim against us, you agree to try to
            resolve the dispute by first emailing <a href="mailto:info@mowpod.com">info@mowpod.com</a> with a
            description of your claim and proof of your relationship with us. We'll try to resolve the dispute
            informally by following up via email, phone or other methods. If we can’t resolve the dispute
            within sixty (60) days of our receipt of your first email, you or we may then bring a formal
            proceeding.
          </p>

          <div>
            <h1 id="privacy-policy">Privacy Policy</h1>
            <p>
              MowMedia and/or MowPod (“MowMedia”, “we”, “us” or “our”) respects your privacy. When it comes to
              your personal information, we believe in transparency.
            </p>
            <p>
              By using any our Services and by visiting our site, you confirm you have agreed to the{' '}
              <a href="terms.html">Terms of Service</a> and read and understood this Privacy Policy (including
              the Cookies section).
            </p>
            <p>
              Certain jurisdictions require us to provide you with specific additional information. Please
              reach out to us for additional information if you are in California, Virginia, Colorado,
              Connecticut or Utah.
            </p>

            <div className="section">
              <h2>1. Some key terms</h2>
              <p>
                In our Privacy Policy, when we refer to “Users”, we mean our customers who use our Services,
                including visitors to our sites.
              </p>
            </div>

            <div className="section">
              <h2>2. How does this Privacy Policy apply?</h2>
              <p>
                This Privacy Policy describes what we do with personal information that we collect and use for
                our own purposes (i.e., where we are a controller), such as your account information and
                information about how you use and interact with our Services.
              </p>
            </div>

            <div className="section">
              <h2>3. Cookies and similar technologies</h2>
              <p>
                We use cookies and similar technologies to provide, customize, evaluate, improve, promote and
                protect our Services. If you have any comments or questions about this Cookie Policy, feel
                free to contact us at <a href="mailto:info@mowpod.com">info@mowpod.com</a>.
              </p>
              <p>
                Cookies are small pieces of text sent to your browser when you visit a site. They serve a
                variety of functions, like enabling us to remember certain information you provide to us as
                you navigate between pages on the Services. We use cookies for the following purposes:
              </p>
              <ul>
                <li>
                  (a) Authentication, Customization, Security and Other Functional Cookies. Cookies help us
                  verify your Account and device and determine when you’re logged in, so we can make it easier
                  for you to access the Services and provide the appropriate experiences and features.
                </li>
                <li>
                  (b) Performance And Analytics. Cookies help us analyze how the Services are being accessed
                  and used, and enable us to track performance of the Services. For example, we use cookies to
                  determine if you viewed a page or opened an email. This helps us provide you with
                  information that you find interesting.
                </li>
                <li>
                  (c) Third Parties. Third Party Services may use cookies to help you sign into their services
                  from our Services. Any such third party cookie usage is governed by the policy of the third
                  party placing the cookie.
                </li>
                <li>
                  (d) Our Ads. We partner with third party publishers, advertising networks and service
                  providers to manage our ads on other sites. Our Third Party partners may set cookies on your
                  device or browser to gather information about your activities on the Services and other
                  sites you visit, in order to provide you with ads. For example, if you visit MowMedia and/or
                  MowPod, you may see ads in your social media newsfeed or timeline.
                </li>
                <li>
                  (e) Opting Out. You can set your browser to not accept cookies, but this may limit your
                  ability to use the Services.
                </li>
                <li>
                  (f) Device Identifiers. We use device identifiers to track, analyze and improve the
                  performance of the Services and our ads.
                </li>
                <li>
                  (g) Third Party Tags. We use and manage third party tags on our website and associated
                  domains. Third party tags may take the form of pixels or tracking snippets. We use pixels to
                  learn how you interact with our site pages and emails, and this information helps us and our
                  ad partners provide you with a more tailored experience. We use tracking snippets to capture
                  data from your browser, make requests to a third party partner or set cookies on your device
                  to store data.
                </li>
              </ul>
              <p>
                When we make changes to our Privacy Policy (or the Cookie section herein), we’ll update the
                “Effective Date” at the top of the and post it on our sites. We encourage you to check back
                periodically to review for any changes since your last visit.
              </p>
            </div>

            <div className="section">
              <h2>4. Personal information we collect</h2>
              <p>
                We collect various personal information regarding you or your device. This includes the
                following:
              </p>
              <ul>
                <li>
                  Information you provide to participate in Surveys, specifically email address, first name
                  and last name, and gender. You can Opt Out of sharing this information by emailing{' '}
                  <a href="mailto:info@mowpod.com">info@mowpod.com</a>.
                </li>
                <li>
                  The emails and other communications that you send us or otherwise contribute, such as
                  customer support inquiries or posts to our customer message boards or forums. Please be
                  aware that information on public parts of our sites is available to others.
                </li>
                <li>
                  Information from your use of the Services. This includes: IP addresses, preferences, web
                  pages you visited prior to coming to our sites, information about your browser, network or
                  device (such as browser type and version, operating system, internet service provider,
                  preference settings, unique device IDs and language and other regional settings),
                  information about how you interact with the Services sites (such as timestamps, clicks,
                  scrolling, browsing times, searches, transactions, referral pages, load times, and problems
                  you may encounter, such as loading errors).
                </li>
                <li>
                  Information we get from our partners to support our marketing initiatives, improve our
                  Services and better monitor, manage and measure our ad campaigns, such as details about when
                  a partner of ours shows you one of our ads on or via its advertising platform.
                </li>
              </ul>
            </div>

            <div className="section">
              <h2>5. How we collect personal information</h2>
              <p>We obtain personal information from various sources. We do this in three main ways:</p>
              <ul>
                <li>You provide some of it directly (such as by participating in a Survey).</li>
                <li>
                  We record some of it automatically when you use our Services (including with technologies
                  like cookies).
                </li>
              </ul>
              <p>We’ve described this in more detail below.</p>
              <h3>a. Personal information you provide</h3>
              <p>
                When you use our Services, we collect information from you in a number of ways. For instance,
                we ask you to provide your name and email address to register and manage your Account. We also
                maintain your marketing preferences and the emails and other communications that you send us
                or otherwise contribute, such as customer support inquiries. You might also provide us with
                information in other ways, including by responding to surveys, submitting a form or
                participating in contests or similar promotions.
              </p>
              <h3>b. Personal information obtained from your use of our Services</h3>
              <p>
                When you use our Services, we collect information about your activity on and interaction with
                the Services, such as your IP address(es), your device and browser type, the web page you
                visited before coming to our sites, what pages on our sites you visit and for how long and
                identifiers associated with your devices. If you’ve given us permission through your device
                settings, we may collect your location information.
              </p>
              <p>
                Some of this information is collected automatically using cookies and similar technologies
                when you use our Services.
              </p>
              <h3>c. Personal information obtained from other sources</h3>
              <p>
                We may enhance the data you provide with additional data from other publicly available sources
                to give a more robust demographic analysis to our advertising customers.
              </p>
            </div>

            <div className="section">
              <h2>6. How we use your personal information</h2>
              <p>We use the personal information we obtain about you for the following purposes:</p>
              <ul>
                <li>
                  Provision of the Services to Users. Provide and personalize our Services, process payments
                  and respond to your inquiries.
                </li>
                <li>
                  Communicating with you. Communicate with you, including by sending you emails about your
                  transactions and Service-related announcements.
                </li>
                <li>Surveys and contests. Administer surveys, contests and other promotions.</li>
                <li>
                  Promotion. Promote our Services and send you tailored marketing communications about
                  products, services, offers, programs and promotions of other surveys.
                </li>
                <li>
                  Advertising. Analyze your interactions with our Services and third parties’ online services
                  so we can tailor our advertising to what we think will interest you. For example, we may
                  decide not to advertise our Services to you on a social media site if you already took a
                  specific survey or we may choose to serve you a particular advertisement based on your
                  survey answers or what we think may interest you based on other information you gave about
                  yourself.
                </li>
                <li>
                  Customizing the Services. Provide you with customized services. For example, we use your
                  location information to determine your language preferences or display accurate date and
                  time information.
                </li>
                <li>
                  Improving our Services. Analyze and learn about how the Services are accessed and used,
                  evaluate and improve our Services (including by developing new products and services and
                  managing our communications) and monitor and measure the effectiveness of our advertising.
                  We usually do this based on anonymous, pseudonymized or aggregated information which does
                  not identify you directly.
                </li>
                <li>Third party relationships. Manage our vendor and partner relationships.</li>
                <li>
                  Protection. Protect our and others’ interests, rights and property (e.g., to protect our
                  Users from abuse).
                </li>
                <li>
                  Complying with law. Comply with applicable legal requirements, such as tax and other
                  government regulations and industry standards, contracts or law enforcement requests.
                </li>
                <li>
                  Consent. You have consented to the use of your personal information in a particular way.
                  When you consent, you can change your mind at any time.
                </li>
                <li>
                  Performance of a contract. We need your personal information to provide you with services
                  and products requested by you, or to respond to your inquiries.
                </li>
                <li>
                  Legal obligation. We have a legal obligation to use your personal information, such as to
                  comply with applicable tax and other government regulations or to comply with a court order
                  or binding law enforcement request.
                </li>
                <li>
                  Legitimate interests. We have a legitimate interest in using your personal information, but
                  we won't process your information if your interests, or your fundamental rights and
                  freedoms, override ours.
                </li>
                <li>Protecting you and others. To protect your vital interests, or those of others.</li>
                <li>
                  Others’ legitimate interests. Where necessary for the purposes of a third party’s legitimate
                  interests, such as our partners who have a legitimate interest in delivering tailored
                  advertising to you and monitoring and measuring its effectiveness.
                </li>
              </ul>
            </div>

            <div className="section">
              <h2>7. How we share your personal information</h2>
              <p>We share personal information in the following ways:</p>
              <ul>
                <li>
                  Survey Customers. We share all personal information with all survey answers given, and any
                  enhanced demographic data with our advertising customer for whom we are conducting the
                  survey you are taking. We do not share this information publicly or with any other
                  advertiser.
                </li>
                <li>
                  Third party plugins and social networks. We may share personal information with website
                  plugins, social media platforms or similar Third Party Services to improve your experience,
                  at your direction, or when you intentionally interact with the plug-in. For example, when
                  you use a Third Party Service to create or log in to your Account, we may share your
                  personal information with that Third Party Service.
                </li>
                <li>
                  Service providers. We share personal information with our service providers that perform
                  services on our behalf. For example, we may use third parties to help us provide customer
                  support, manage our advertisements on other sites, send marketing and other communications
                  on our behalf or assist with data storage.
                </li>
                <li>
                  Process payments. We transmit some of your personal information via an encrypted connection
                  to our payment processor.
                </li>
                <li>
                  Following the law or protecting rights and interests. We disclose your personal information
                  if we determine that such disclosure is reasonably necessary to comply with the law, protect
                  our or others’ rights, property or interests (such as enforcing our Terms of Service) or
                  prevent fraud or abuse. In particular, we may disclose your personal information in response
                  to lawful requests by public authorities, such as to meet national security or law
                  enforcement requirements.
                </li>
                <li>
                  Advertising. We share personal information with third parties so they and we can provide you
                  with tailored advertising and measure and monitor its effectiveness. For example, we may
                  share your pseudonymized email address with a third party social media platform on which we
                  advertise.
                </li>
              </ul>
            </div>

            <div className="section">
              <h2>8. Your rights and choices</h2>
              <p>
                Where applicable law requires (and subject to any relevant exceptions under law), you may have
                the right to access, update, change or delete personal information. You can access, update,
                change or delete personal information and you can also elect not to receive marketing
                communications by unsubscribing via <a href="mailto:info@MowPod.com">info@MowPod.com</a>.
              </p>
              <p>
                Please note that, for technical reasons, there is likely to be a delay in deleting your
                personal information from our systems when you ask us to delete it. We also will retain
                personal information in order to comply with the law, protect our and others’ rights, resolve
                disputes or enforce our legal terms or policies, to the extent permitted under applicable law.
              </p>
              <p>
                You may have the right to restrict or object to the processing of your personal information or
                to exercise a right to data portability under applicable law. You also may have the right to
                lodge a complaint with a competent supervisory authority, subject to applicable law.
              </p>
            </div>

            <div className="section">
              <h2>9. How we protect your personal information</h2>
              <p>
                While no service is completely secure, we maintain certain safeguards that are intended to
                appropriately protect against accidental or unlawful destruction, accidental loss,
                unauthorized alteration, unauthorized disclosure or access, misuse and any other unlawful form
                of processing, of the personal information in our possession. We employ security measures such
                as using firewalls to protect against intruders, building redundancies throughout our network
                (so that if one server goes down, another can cover for it) and testing for and protecting
                against network vulnerabilities.
              </p>
            </div>

            <div className="section">
              <h2>10. How we retain your personal information</h2>
              <p>
                We retain personal information regarding you or your use of the Services for as long as needed
                to provide you with the Services. We also retain personal information for as long as necessary
                to achieve the purposes described in this Privacy Policy, for example, to comply with our
                legal obligations, to protect us in the event of disputes and to enforce our agreements and to
                protect our and others’ interests.
              </p>
              <p>
                The precise periods for which we keep your personal information vary depending on the nature
                of the information and why we need it. Factors we consider in determining these periods
                include the minimum required retention period prescribed by law or recommended as best
                practice, the period during which a claim can be made with respect to an agreement or other
                matter, whether the personal information has been aggregated or pseudonymized, and other
                relevant criteria. For example, the period we keep your email address is connected to how long
                your Account is active, while the period for which we keep a support message is based on how
                long has passed since the last submission in the thread.
              </p>
              <p>
                You may delete your Account by contacting us at{' '}
                <a href="mailto:info@mowpod.com">info@mowpod.com</a> and we will delete the personal
                information we hold about you (unless we need to retain it for the purposes set out in this
                Privacy Policy).
              </p>
              <p>
                Please note that in the course of providing the Services, we collect and maintain aggregated,
                anonymized or de-personalized information which we may retain indefinitely.
              </p>
            </div>

            <div className="section">
              <h2>Updates to this Privacy Policy</h2>
              <p>
                We’ll update this Privacy Policy from time to time to reflect changes in technology, law, our
                business operations or any other reason we determine is necessary or appropriate. When we make
                changes, we’ll update the “Effective Date” at the top of the Privacy Policy and post it on our
                sites. If we make material changes to it or the ways we process personal information, we’ll
                notify you (by, for example, prominently posting a notice of the changes on our sites before
                they take effect or directly sending you a notification).
              </p>
              <p>
                We encourage you to check back periodically to review this Privacy Policy for any changes
                since your last visit. This will help ensure you better understand your relationship with us,
                including the ways we process your personal information.
              </p>
            </div>

            <div className="section">
              <h2>How to contact us</h2>
              <p>
                If you have questions, comments or complaints about this Privacy Policy or our privacy
                practices or if you would like to exercise your rights and choices, please email us at{' '}
                <a href="mailto:info@mowpod.com">info@mowpod.com</a>.
              </p>
            </div>
          </div>
        </div>
        <button className="modal-close button" onClick={toggleTermsOfService}>
          CLOSE
        </button>
      </div>
    </div>
  );
};

export default TermsOfService;
