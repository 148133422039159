import TermsOfService from '../TermsOfService/TermsOfService';
import BackgroundAssets from '../BackgroundAssets/BackgroundAssets';
import logo from '../../images/logo.svg';
import listenOnApple from '../../images/listen-on-apple.png';
import listenOnSpotify from '../../images/listen-on-spotify.png';
import listenNow from '../../images/listen-now.png';
import cx from 'classnames';

import '../../styles/styles.scss';
import { useEffect, useRef, useState } from 'react';
import { getSurveyAnswers, updateSurveyAnswers, scrollToRef } from '../../utils/utils';
import { useParams, useNavigate } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyProvider';
import { saveIntroduction } from '../../api/survey';
import RadioButton from '../RadioButton/RadioButton';
import TextInput from '../TextInput/TextInput';
import Checkbox from '../Checkbox/Checkbox';

const GENRES = [
  'arts',
  'business',
  'comedy',
  'education',
  'health',
  'history',
  'hobbies',
  'music',
  'news',
  'religion',
  'science',
  'society',
  'sports',
  'technology',
  'true-crime',
  'no-podcasts',
];
const Introduction = () => {
  const { surveyName } = useParams();
  const navigate = useNavigate();
  const { surveyData } = useSurvey();
  const [errors, setErrors] = useState({
    name: { error: false },
    email: { error: false, message: '' },
    'terms-of-use': { error: false },
  });
  const [termsIsOpen, setTermsIsOpen] = useState(false);

  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const termsRef = useRef(null);

  const [introductionAnswers, setIntroductionAnswers] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;

    setIntroductionAnswers((prev) => ({ ...prev, [name]: value }));

    if (value.trim()) {
      clearErrors(name);
    }
  };

  const handleGenresChange = (event) => {
    const { value } = event.target;
    const currentGenres = introductionAnswers.genres || [];

    const isSelected = currentGenres.includes(value);
    const updatedGenres = isSelected
      ? currentGenres.filter((genre) => genre !== value)
      : [...currentGenres, value];

    setIntroductionAnswers((prev) => ({ ...prev, genres: updatedGenres }));
  };

  useEffect(() => {
    updateSurveyAnswers('introduction', {
      ...introductionAnswers,
    });
  }, [introductionAnswers]);

  const toggleTermsOfService = () => setTermsIsOpen((prev) => !prev);

  const validateIntroduction = () => {
    const { name, email, 'terms-of-use': termsOfUse } = introductionAnswers;

    if (!email) highlightErrors('email', emailRef);
    if (!name || name.length < 2) highlightErrors('name', nameRef);
    if (!termsOfUse) highlightErrors('terms-of-use', termsRef);

    return email && name && termsOfUse;
  };

  const highlightErrors = (field, ref, message) => {
    setErrors((prev) => ({ ...prev, [field]: { error: true, message } }));
    scrollToRef(ref);
  };

  const clearErrors = (field) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: { error: false },
    }));
  };

  const handleNextStep = (event) => {
    event.preventDefault();

    if (validateIntroduction()) {
      const body = { surveyId: surveyData.surveyId, ...getSurveyAnswers() };

      saveIntroduction(body)
        .then(() => {
          navigate(`/survey/${surveyName}/quiz`);
        })
        .catch((error) => {
          const { errorMessage, statusCode } = error.response.data;
          if (statusCode >= 400) {
            highlightErrors('email', emailRef, errorMessage);
          }
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      event.target.previousSibling.click();
    }
  };

  return (
    <div className="step-one">
      <BackgroundAssets />
      <main>
        <div className="head">
          <div className="head_left">
            <img src={logo} alt="logo" className="logo" />
            <h1>mowPod Survey: {surveyData.name}</h1>
            <p>
              Welcome to the mowpod survey for the {surveyData.name}! The owners of the podcast would love to
              get your feedback on the show and would love to pay you for your time! Please follow the
              instructions below. Tell us a little bit about yourself, then listen to an episode of the
              podcast. You'll then be given a bunch of questions to answer. Don't worry, the questions are
              short. It should only take a few minutes!
            </p>
          </div>
          <div className="head_right">
            {surveyData.episodeImage && <img src={surveyData.episodeImage} alt="Top Episode" />}
          </div>
        </div>

        <div className="circle-container">
          <div className="circle">A</div>
          <h2>Tell us about yourself!</h2>
        </div>

        <form className="survey-form step1" id="1">
          <fieldset>
            <TextInput
              type="email"
              name="email"
              placeholder="name@email.com"
              handleChange={handleChange}
              ref={emailRef}
              errorBorderFor={errors.email.error}
              labelText="1. Enter your email address:"
            />
            {errors.email.error && <div className="err-msg">{errors.email.message || 'Required'}</div>}
          </fieldset>

          <fieldset>
            <TextInput
              type="text"
              name="name"
              placeholder="your Name here"
              handleChange={handleChange}
              ref={nameRef}
              errorBorderFor={errors.name.error}
              labelText="2. Enter your First Name:"
            />
            {errors.name.error && <div className="err-msg">The name must be contain at least 2 letters</div>}
          </fieldset>

          <fieldset>
            <legend>3. Gender</legend>
            <div className="radio-btn__wrapper">
              <RadioButton
                name="gender"
                value="male"
                handleClick={handleChange}
                handleKeyDown={handleKeyDown}
                text="Male"
              />
              <RadioButton
                name="gender"
                value="female"
                handleClick={handleChange}
                handleKeyDown={handleKeyDown}
                text="Female"
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>4. What podcast genres are you interested in?</legend>
            <div className="checkbox-btn__wrapper">
              {GENRES.map((genre) => (
                <Checkbox
                  key={genre}
                  name="genres"
                  value={genre}
                  handleClick={handleGenresChange}
                  handleKeyDown={handleKeyDown}
                  text={genre.charAt(0).toUpperCase() + genre.slice(1).replace('-', ' ')}
                />
              ))}
            </div>
          </fieldset>

          <fieldset>
            <legend>5. What other podcasts do you listen to?</legend>
            <textarea
              onChange={handleChange}
              name="other-podcasts"
              rows="4"
              cols="50"
              tabIndex="1"
            ></textarea>
            <br />
            <br />
          </fieldset>

          <div className="bottom">
            <div className="bottom__text">
              <div className="circle">B</div>
              <p>
                <span>IMPORTANT:</span> Now it's time for you to listen to the '{surveyData.name}'! Please be
                sure to listen to at least 25 minutes of the podcast. You will be asked to answer 5 questions
                about the content in order to prove you listened to the show before being able to complete
                this survey and receive your reward. If you answer a question incorrectly, you will be
                disqualified from the survey.{' '}
                <a href={surveyData.appleEpisodeLink} target="_blank" rel="noopener noreferrer">
                  Click here to start listening on Apple Podcasts
                </a>{' '}
                or{' '}
                <a href={surveyData.spotifyEpisodeLink} target="_blank" rel="noopener noreferrer">
                  Spotify
                </a>
                .
              </p>
            </div>

            <div className="bottom__play-container">
              {surveyData.episodeImage && <img className="play" src={surveyData.episodeImage} alt="Play" />}
              <div className="play-links">
                <a
                  href={surveyData.appleEpisodeLink}
                  id="apple-follow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="apple" src={listenOnApple} alt="Listen on Apple" />
                </a>
                <a
                  href={surveyData.spotifyEpisodeLink}
                  id="spotify-follow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="spotify" src={listenOnSpotify} alt="Listen on Spotify" />
                </a>
              </div>
              <img className="listen-now" src={listenNow} alt="Listen Now" />
            </div>

            <div>
              <fieldset className="terms-of-use">
                <div
                  className={cx('terms-of-use__container', { 'alerts-border': errors['terms-of-use'].error })}
                >
                  <div className="checkbox-btn__wrapper">
                    <label>
                      <input type="checkbox" name="terms-of-use" onClick={handleChange} ref={termsRef} />
                      <div className="checkbox-container" tabIndex="1" onKeyDown={handleKeyDown}></div>
                      <span className="checkbox-label"></span>
                    </label>
                  </div>
                  <span className="terms-of-use__btn" onClick={toggleTermsOfService}>
                    I have read and agree to the privacy policy and terms of use.
                  </span>
                </div>
                {errors['terms-of-use'].error && <div className="err-msg">Required</div>}
              </fieldset>
            </div>

            <div className="bottom__next-step">
              <div>
                <div className="circle">C</div>
                <h2>Once you've listened to the podcast, click NEXT</h2>
              </div>
              <button className="submit-btn" onClick={(e) => handleNextStep(e)}>
                NEXT
              </button>
            </div>
          </div>
        </form>
      </main>

      {termsIsOpen && <TermsOfService toggleTermsOfService={toggleTermsOfService} />}
    </div>
  );
};

export default Introduction;
