const Checkbox = ({ name, value, handleClick, handleKeyDown, text }) => {
  return (
    <label>
      <input type="checkbox" name={name} value={value} onClick={handleClick} />
      <div className="checkbox-container" tabIndex="1" onKeyDown={handleKeyDown}></div>
      <span className="checkbox-label">{text}</span>
    </label>
  );
};

export default Checkbox;
