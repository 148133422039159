import React, { createContext, useContext, useState } from 'react';

const SurveyContext = createContext();

export const useSurvey = () => {
  return useContext(SurveyContext);
};

export const SurveyProvider = ({ children }) => {
  const [surveyData, setSurveyData] = useState({});

  const updateSurveyData = (data) => {
    setSurveyData((prevData) => ({
      ...prevData,
      ...data,
      questions: data.questions || prevData.questions,
    }));
  };

  return <SurveyContext.Provider value={{ surveyData, updateSurveyData }}>{children}</SurveyContext.Provider>;
};
