import { forwardRef } from 'react';
import cx from 'classnames';

const TextInput = forwardRef(({ type, name, placeholder, handleChange, labelText, alertsBorder }, ref) => {
  return (
    <>
      <label className="legend-label" htmlFor={name}>
        {labelText}
      </label>
      <br />
      <input
        className={cx('text-input', { 'alerts-border': alertsBorder })}
        type={type}
        id={name}
        name={name}
        required
        placeholder={placeholder}
        tabIndex="1"
        onChange={handleChange}
        ref={ref}
      />
    </>
  );
});

export default TextInput;
