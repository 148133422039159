const RadioButton = ({ name, value, handleClick, handleKeyDown, text }) => {
  return (
    <label>
      <input type="radio" name={name} value={value} onClick={handleClick} />
      <div className="radio-container" tabIndex="1" onKeyDown={handleKeyDown}></div>
      <span className="radio-label">{text}</span>
    </label>
  );
};

export default RadioButton;
