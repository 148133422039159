import React from 'react';
import cx from 'classnames';

export const Textarea = ({errors, question, ...rest}) => {
  return (
    <textarea
      className={cx('feedback-fields', { 'alerts-border': errors[question.fieldName] })}
      name={question.fieldName}
      rows="4"
      cols="50"
      tabIndex="1"
      {...rest}
    ></textarea>
  );
};
