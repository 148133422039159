import React from 'react';
import cx from 'classnames';
import Select from 'react-select';

export const Dropdown = ({ errors, question, onChange }) => {
  const options = question.answers.map(({ answer, value }) => ({ value, label: answer }));
  const handleChange = (e) => {
    const event = {
      target: { name: question.fieldName, value: e.value },
    };
    onChange(event);
  }
  return (
    <div className={cx('radio-btn__wrapper', { 'alerts-border': errors[question.fieldName] })}>
      <Select
        name={question.fieldName}
        options={options}
        isSearchable={false}
        onChange={handleChange}
        styles={{
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: '#000',
          }),
        }}
      />
    </div>
  );
};
