import React from 'react';
import { Textarea } from './Textarea';
import { Radio } from './Radio';
import { Dropdown } from './Dropdown';

export const FeedbackInput = ({ type, ...props }) => {
  const { question, errors } = props;
  const inputs = {
    textarea: <Textarea {...props} />,
    radio: <Radio {...props} />,
    select: (<Dropdown {...props}/>),
  };
  return (
    <>
      <legend>{question.question}</legend>
      {inputs[type]}
      {errors[question.fieldName] && <div className="err-msg">{errors[question.fieldName]}</div>}
    </>
  );
};
