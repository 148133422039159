import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import './styles/styles.scss';
import Onboarding from './pages/Onboarding/Onboarding';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/survey/:surveyName/:stepName?" element={<SurveyPage />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/" element={<div>Welcome to the Survey App</div>} />
      </Routes>
    </Router>
  );
}

export default App;
