import React from 'react';
import cx from 'classnames';

export const Radio = ({ errors, question, ...rest }) => {
  const direction = question.answers.length > 5 ? 'direction-row' : 'direction-column';
  return (
    <div className={cx('radio-btn__wrapper', direction,  { 'alerts-border': errors[question.fieldName] })}>
      {question.answers.map((answer, answerIndex) => (
        <label className="years" key={answerIndex}>
          <input
            type="radio"
            name={question.fieldName}
            value={answer.value}
            {...rest}
          />
          <div className="radio-container" tabIndex="1"></div>
          <span className="radio-label">{answer.answer}</span>
        </label>
      ))}
    </div>
  );
};
