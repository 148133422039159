import { useEffect } from 'react';
import cassette from '../../images/casette.png';
import logo from '../../images/logo.svg';
import followApple from '../../images/follow-apple.png';
import followSpotify from '../../images/follow-spotify.png';
import facebook from '../../images/facebook.png';
import { useSurvey } from '../../context/SurveyProvider';
import { getSurveyAnswers } from '../../utils/utils';
import airpodRight from '../../images/airpod-right.png';
import { saveSocialMediaClick } from '../../api/survey';

const ThankYou = () => {
  const { surveyData } = useSurvey();
  const { appleLink, spotifyLink, fbGroupLink } = surveyData;
  const feedback = getSurveyAnswers().feedback;

  const interestedToJoin = feedback['be-interested'] && feedback['be-interested'] !== 'no';
  const wouldListen = feedback['would-listen'] && feedback['would-listen'] !== 'no';

  console.log(feedback);

  useEffect(() => {
    if (window.EF) {
      window.EF.conversion({
        aid: 1120,
      });
    }
  }, []);

  const renderFollowLinks = () => {
    return (
      <div className="follow-container podcast-links">
        <p className="follow-text">
          {' '}
          As you mentioned, you'd be interested in listening to more episodes of {surveyData.name}, you can
          follow the podcast here:
        </p>
        <a href={appleLink} id="apple-follow" target="_blank" onClick={handleOnClick}>
          <img className="apple" src={followApple} alt="" />
        </a>
        <a href={spotifyLink} id="spotify-follow" target="_blank" onClick={handleOnClick}>
          <img className="spotify" src={followSpotify} alt="" />
        </a>
      </div>
    );
  };

  const renderFbLink = () => {
    return (
      <div className="follow-container facebook-group">
        <p className="join-us-text">
          As you mentioned, you would be interested in joining the {surveyData.name} Facebook group, you can
          join for free here:
        </p>
        <a href={fbGroupLink} id="fb-group" target="_blank" onClick={handleOnClick}>
          <img src={facebook} alt="" />
        </a>
      </div>
    );
  };

  const handleOnClick = (event) => {
    saveSocialMediaClick({
      introduction: getSurveyAnswers().introduction,
      clickedBtn: event.currentTarget.id,
    });
  };

  return (
    <>
      <div className="rainbow rainbow-right rain"></div>
      <img className="casette" src={cassette} alt="casette" />
      <main className="thank-you">
        <img src={logo} alt="logo" className="logo" />
        <h1>THANK YOU FOR COMPLETING OUR SURVEY!</h1>
        <div className="thank-you_content">
          <div className="thank-you_content__text">
            <img className="airpod-right" src={airpodRight} />
            <div className="text-wrapper">
              <h2>WE WILL NOW PROCESS YOUR REWARD PAYMENT.</h2>
              {wouldListen && renderFollowLinks()}
              {interestedToJoin && renderFbLink()}
            </div>
          </div>
        </div>
        <footer>
          <p>© 2019-2024 mowMedia LLC, All Rights Reserved.</p>
        </footer>
      </main>
    </>
  );
};

export default ThankYou;
