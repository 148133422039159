import React from 'react';
import Introduction from '../Introduction/Introduction';
import Feedback from '../Feedback/Feedback';
import Quiz from '../Quiz/Quiz';
import Sorry from '../Sorry/Sorry';
import ThankYou from '../ThankYou/ThankYou';

function Survey({ currentStep }) {
  const renderStep = () => {
    switch (currentStep) {
      case 'introduction':
        return <Introduction />;
      case 'quiz':
        return <Quiz />;
      case 'feedback':
        return <Feedback />;
      case 'thank-you':
        return <ThankYou />;
      case 'sorry':
        return <Sorry />;
      default:
        return <Introduction />;
    }
  };

  return <div>{renderStep()}</div>;
}

export default Survey;
